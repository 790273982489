/* ----------------------------------------------------------------------------------------------------- */


/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

// Page Layouts
$carded-header-height: 200px !default;
$carded-header-height-sm: 160px !default;
$carded-toolbar-height: 64px !default;
$header-height: 120px !default;
$header-height-sm: 100px !default;
// Calculate toolbarless header height
$carded-header-height-without-toolbar: $carded-header-height - $carded-toolbar-height;
$carded-header-height-without-toolbar-sm: $carded-header-height-sm - $carded-toolbar-height;
.page-layout {
    position: relative;
    overflow: hidden;
    // Carded layout
    &.carded {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;
        // Top bg
        >.top-bg {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            left: 0;
            height: $carded-header-height;
            // @include media-breakpoint-down('sm') {
            //     height: $carded-header-height-sm;
            // }
        }
        // Fullwidth
        &.fullwidth {
            // Center
            >.center {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
                position: relative;
                z-index: 2;
                padding: 0 32px;
                width: 100%;
                min-width: 0;
                max-width: 100%;
                max-height: 100%;
                >.header {
                    height: $carded-header-height-without-toolbar;
                    min-height: $carded-header-height-without-toolbar;
                    max-height: $carded-header-height-without-toolbar;
                    // @include media-breakpoint-down('sm') {
                    //     height: $carded-header-height-without-toolbar-sm ;
                    //     min-height: $carded-header-height-without-toolbar-sm ;
                    //     max-height: $carded-header-height-without-toolbar-sm ;
                    // }
                }
                >.content-card {
                    display: flex;
                    flex-direction: column;
                    flex: 1 0 auto;
                    overflow: hidden;
                    box-shadow: 0 4px 5px -2px rgb(0 0 0 / 20%), 0 7px 10px 1px rgb(0 0 0 / 14%), 0 2px 16px 1px rgb(0 0 0 / 12%);
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.87) !important;
                    .dark & {
                        background-color: #1e293b;
                    }
                    // @include mat-elevation(7);
                    >.toolbar {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        height: $carded-toolbar-height;
                        min-height: $carded-toolbar-height;
                        max-height: $carded-toolbar-height;
                    }
                    >.content {
                        flex: 1 0 auto;
                    }
                }
            }
            // Tabbed
            &.tabbed {
                >.center {
                    width: 100%;
                    min-width: 0;
                    >.header {
                        flex: 1 1 auto;
                    }
                    >.content-card {
                        >.content {
                            display: flex;
                            >.mat-tab-group {
                                overflow: hidden;
                                .mat-tab-header {
                                    .mat-tab-label {
                                        height: 64px;
                                    }
                                }
                                .mat-tab-body {
                                    overflow: hidden;
                                    .mat-tab-body-content {
                                        overflow: hidden;
                                        .tab-content {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Inner scroll
            &.inner-scroll {
                flex: 1 1 auto;
                >.center {
                    flex: 1 1 auto;
                    >.content-card {
                        flex: 1 1 auto;
                        >.content {
                            overflow: auto;
                            flex: 1 1 auto;
                        }
                    }
                }
                // Tabbed
                &.tabbed {
                    >.center {
                        >.content-card {
                            >.content {
                                >.mat-tab-group {
                                    .mat-tab-body {
                                        .mat-tab-body-content {
                                            .tab-content {
                                                overflow: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // Left / Right sidebar
        &.left-sidebar,
        &.right-sidebar {
            flex-direction: row;
            // Sidebar
            >.sidebar {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                height: auto;
                overflow: hidden;
                // @include mat-elevation(7);
                &.locked-open {
                    background: none;
                    box-shadow: none;
                    +.center {
                        z-index: 1001;
                    }
                    &.left-positioned {
                        +.center {
                            margin-left: 0;
                        }
                    }
                    &.right-positioned {
                        +.center {
                            margin-right: 0;
                        }
                    }
                }
                .header {
                    height: $carded-header-height;
                    min-height: $carded-header-height;
                    max-height: $carded-header-height;
                    // @include media-breakpoint-down('sm') {
                    //     height: $carded-header-height-sm;
                    //     min-height: $carded-header-height-sm;
                    //     max-height: $carded-header-height-sm;
                    // }
                }
                .content {
                    background: transparent;
                    flex: 1 1 auto;
                }
            }
            // Center
            >.center {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                position: relative;
                z-index: 3;
                margin-left: 32px;
                margin-right: 32px;
                min-width: 0;
                >.header {
                    display: flex;
                    height: $carded-header-height-without-toolbar;
                    min-height: $carded-header-height-without-toolbar;
                    max-height: $carded-header-height-without-toolbar;
                    // @include media-breakpoint-down('sm') {
                    //     height: $carded-header-height-without-toolbar-sm;
                    //     min-height: $carded-header-height-without-toolbar-sm;
                    //     max-height: $carded-header-height-without-toolbar-sm;
                    // }
                }
                >.content-card {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    overflow: hidden;
                    // @include mat-elevation(7);
                    >.toolbar {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex: 1 1 auto;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        height: $carded-toolbar-height;
                        min-height: $carded-toolbar-height;
                        max-height: $carded-toolbar-height;
                        .sidebar-toggle {
                            margin: 0 8px 0 0 !important;
                            padding: 0 !important;
                            border-radius: 0;
                        }
                    }
                    >.content {
                        flex: 1 1 auto;
                    }
                }
            }
            // Tabbed
            &.tabbed {
                >.center {
                    width: calc(100% - 32px);
                    min-width: 0;
                    // @include media-breakpoint-down('md') {
                    //     width: calc(100% - 64px);
                    // }
                    >.header {
                        flex: 1 1 auto;
                    }
                    >.content-card {
                        >.content {
                            display: flex;
                            >.mat-tab-group {
                                overflow: hidden;
                                .mat-tab-header {
                                    .mat-tab-label {
                                        height: 64px;
                                    }
                                }
                                .mat-tab-body {
                                    overflow: hidden;
                                    .mat-tab-body-content {
                                        overflow: hidden;
                                        .tab-content {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Inner scroll
            &.inner-scroll {
                flex: 1 1 auto;
                >.sidebar {
                    .content {
                        overflow: auto;
                    }
                }
                >.center {
                    flex: 1 1 auto;
                    >.content-card {
                        flex: 1 1 auto;
                        >.content {
                            flex: 1 1 auto;
                            overflow: auto;
                        }
                    }
                }
                // Tabbed
                &.tabbed {
                    >.center {
                        >.content-card {
                            >.content {
                                >.mat-tab-group {
                                    .mat-tab-body {
                                        .mat-tab-body-content {
                                            .tab-content {
                                                overflow: auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        // Right sidebar specific
        &.right-sidebar {
            >.sidebar {
                order: 2;
            }
            >.center {
                order: 1;
            }
        }
    }
    // Simple layout
    &.simple {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;
        // Fullwidth
        &.fullwidth {
            >.content {
                flex: 1 1 auto;
                min-width: 0;
            }
        }
        &.fullwidth,
        &.inner-sidebar {
            >.header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
            }
        }
        // Left / Right sidebar
        &.left-sidebar,
        &.right-sidebar {
            flex-direction: row;
            // Sidebar
            >.sidebar {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                overflow: hidden;
                // @include mat-elevation(7);
                &.locked-open {
                    background: none;
                    box-shadow: none;
                    +.center {
                        z-index: 1001;
                    }
                    &.left-positioned {
                        +.center {
                            margin-left: 0;
                        }
                    }
                    &.right-positioned {
                        +.center {
                            margin-right: 0;
                        }
                    }
                }
                .content {
                    flex: 1 1 auto;
                }
            }
            // Center
            >.center {
                position: relative;
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                z-index: 3;
                min-width: 0;
                // @include mat-elevation(7);
                >.header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                }
                >.content {
                    flex: 1 0 auto;
                }
            }
            // Inner scroll
            &.inner-scroll {
                flex: 1 1 auto;
                >.sidebar {
                    .content {
                        overflow: auto;
                    }
                }
                >.center {
                    flex: 1 1 auto;
                    overflow: auto;
                }
            }
            // Inner Sidebar
            &.inner-sidebar {
                flex-direction: column;
                overflow: hidden;
                height: 100%;
                >.content {
                    display: flex;
                    min-height: 0;
                    >.sidebar {
                        &.locked-open {
                            background: none;
                            box-shadow: none;
                        }
                        .content {
                            overflow: auto;
                        }
                    }
                    >.center {
                        flex: 1 1 auto;
                        overflow: auto;
                    }
                }
            }
        }
        // Right sidebar specific
        &.right-sidebar {
            >.sidebar {
                order: 2;
            }
            >.center {
                order: 1;
            }
            // Inner sidebar
            &.inner-sidebar {
                >.content {
                    >.sidebar {
                        order: 2;
                    }
                    >.center {
                        order: 1;
                    }
                }
            }
        }
        // Tabbed
        &.tabbed {
            min-height: 100%;
            >.header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
            }
            >.content {
                >.mat-tab-group {
                    .mat-tab-labels {
                        padding: 0 24px;
                    }
                }
            }
        }
    }
    // Blank layout
    &.blank {
        width: 100%;
        min-height: 100%;
    }
    // @include media-breakpoint-down('xs') {
    //     // Smaller margins
    //     &.carded {
    //         &.fullwidth {
    //             > .center {
    //                 padding: 0 16px;
    //             }
    //         }
    //         &.left-sidebar,
    //         &.right-sidebar {
    //             > .center {
    //                 margin: 0 16px;
    //             }
    //         }
    //     }
    //     // End - Smaller margins
    // }
    .fuse-card {
        background: #fff;
        border-radius: 2px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        margin-bottom: 30px;
        .dark & {
            background: #0f172a !important;
        }
        .h1 {
            .dark & {
                color: #fff !important;
            }
        }
        .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
            .dark & {
                color: #fff !important;
            }
        }
        .mat-checkbox {
            .dark & {
                color: #fff !important;
            }
        }
    }
    .tab-content {
        background-color: #f1f5f9;
        .dark & {
            background-color: #0f172a !important;
        }
        .mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element {
            .dark & {
                color: white !important;
            }
        }
    }
    .content-field {
        .dark & {
            color: white !important;
        }
    }
}